
import {
  reactive,
  toRefs,
  computed,
  inject,
} from "vue"
import { useRouter, useRoute } from 'vue-router'

import { Area, Popup } from 'vant'
import { areaList } from '@vant/area-data'

import { 
  createAddressApi,
} from "@/api/address"
import {
  checkAddressForm,
  defaultError,
} from '@/utils/common.js'

export default {
  name: 'FillAddress',
  components: {
    [Area.name]: Area,
    [Popup.name]: Popup,
  },
  setup() {
    const state = reactive({
      name: '',
      phone: '',
      areaArr: [],// 省/市/区
      address: '',
      defaultChecked: true,
      isShowArea: false,
    })

    const router = useRouter()
    const route = useRoute()
    const toast = inject('toast')
    const loading = inject('loading')

    const areaString = computed(() => state.areaArr.join(' '))
    const showArea = () => {
      state.isShowArea = true
    }
    const areaConfirm = (res) => {
      state.areaArr = []
      res.map( x => {
        if(x && x.name) {
          state.areaArr.push(x.name)
        }
      })

      state.isShowArea = false
    }

    const toggleSwitch = () => {
      state.defaultChecked = !state.defaultChecked
    }

    const onCancel = () => {
      router.go(-1)
    }
    const onSave = async () => {
      const checkFormRes = checkAddressForm({
        name: state.name,
        phone: state.phone,
        areaString: areaString,
        address: state.address,
      })
      if(!checkFormRes.success) {
        toast({
          duration: 1500,
          message: checkFormRes.error
        })
        return
      }
      
      loading.show()
      const res = await createAddressApi({
        province: state.areaArr[0],
        city: state.areaArr[1],
        district: state.areaArr[2],
        street: state.address,
        telephone: state.phone,
        consignee: state.name,
        isDefault: state.defaultChecked,
      })
      loading.hide()

      if(res.code === 0) {
        // from: 表示如何进入这个页面
        if(route.query.from === 'settlement') {
          // settlement-结算时，没有地址，先填写地址。所以保存后去提交订单页
          router.replace({name: 'SubmitOrder'})
        }
        else {
          // 没值-保存后，返回上一页。现在只有一种情况，从地址列表页进入
          router.go(-1)
        }
      }
      else {
        toast({
          message: res.message || defaultError,
        })
      }
    }

    return {
      ...toRefs(state),
      areaList,
      areaString,
      showArea,
      areaConfirm,
      toggleSwitch,
      onSave,
      onCancel,
    }
  },
} 